import 'normalize.css';

import { PageProps } from 'gatsby';
import styled from '@emotion/styled';
import React, { useEffect } from 'react';

import { getGradientColors } from '../../../../utils';
import { Layout } from '../../../../components/layout';
import { Rectangle } from '../../../../components/rectangle';
import { useWindowSize } from '../../../../hooks/useWindowSize';

interface ContainerProps {
  clipPathRadius?: number;
  display: string;
  height: string;
}

const Container = styled.div<ContainerProps>`
  display: ${({ display }) => display};
  width: 100vw;
  height: ${({ height }) => height};
  ${({ clipPathRadius }) =>
    clipPathRadius ? `clip-path: circle(${clipPathRadius}px)` : ''};
`;

const GradientPage = ({ params }: PageProps) => {
  const { type = 'gradients', stops = 10, c1, c2 } = params;
  const { width = 20, height = 20 } = useWindowSize();

  let display = 'grid';
  let containerHeight = '100%';
  let rectangleWidth = '100%';
  let rectangleHeight = '100%';
  let clipPathRadius = undefined;

  // have to do this for the build
  useEffect(() => {
    if (typeof document !== 'undefined') {
      const main = document.querySelector('main');
      if (!main?.classList.contains(type)) {
        main?.classList.add(type);
      }
    }
  }, []);

  switch (type) {
    case 'circle': {
      const diameter = height - 100;
      // const diameter = height;
      clipPathRadius = diameter / 2;
      rectangleHeight = `${diameter / Number(stops)}px`;
      break;
    }

    case 'vertical':
      display = 'flex';
      containerHeight = '100vh';
      rectangleWidth = `${width / Number(stops)}px`;
      break;

    default:
      // horizontal
      rectangleHeight = `${height / Number(stops)}px`;
      break;
  }

  const colors = getGradientColors({ c1, c2 }, Number(stops));

  return (
    <Layout
      className={type}
      title={type.charAt(0).toUpperCase() + type.slice(1)}
    >
      <Container
        clipPathRadius={clipPathRadius}
        display={display}
        height={containerHeight}
      >
        {colors.map(c => (
          <Rectangle
            width={rectangleWidth}
            height={rectangleHeight}
            color={c.toRgbString()}
          />
        ))}
      </Container>
    </Layout>
  );
};

export default GradientPage;
